import { PipelinePlugin } from '@ads/plugin-pipeline';
import { deviceApiPlugin } from '../api';
import { deviceServicePlugin } from '../services';

export class UpdateDeviceInfoPlugin<T> implements PipelinePlugin<T> {
  public async execute(dto: T): Promise<T> {
    const deviceInfo = await deviceServicePlugin.get().gatherDeviceInfo();
    await deviceApiPlugin.get().updateDevice(deviceInfo);
    return dto;
  }
}
