import { authServicePlugin } from '@/features/core/auth';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { dataSyncServicePlugin } from '@/features/data-sync';
import { deviceIdServicePlugin } from '@/features/device-id';
import { ordersServicePlugin } from '@/features/orders/services';
import { productsServicePlugin } from '@/features/products/services';
import { pushNotificationServicePlugin } from '@/features/push-notification';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { DeviceService } from './device-service';

export * from './device-service';

export const deviceServicePlugin = ProviderPluginFactory.create({
  key: Symbol('DeviceService'),
  defaultFactory: {
    create: () => {
      return new DeviceService(
        entityRepositoryPlugin.get(),
        deviceIdServicePlugin.get(),
        pushNotificationServicePlugin.get(),
        authServicePlugin.get(),
        syncSchedulerServicePlugin.get(),
        dataSyncServicePlugin.get(),
        ordersServicePlugin.get(),
        productsServicePlugin.get(),
      );
    },
  },
});
