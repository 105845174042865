import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import { DeviceApiClient } from './device-api';
import { Device } from '../entities';

export const registerDeviceApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  deviceApiClient: DeviceApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(Device, {
    save: deviceApiClient.save.bind(deviceApiClient),
  });
};
