import { UseDropsMiddlemile } from '../types/useDropsMiddlemile';
import {
  DropActionsMiddlemile,
  DropStatusMiddlemile,
  TourDrop,
} from '../types';
import { dropsServicePlugin } from '../services';
import { errorPlugin } from '@/features/core/errors';
import { useRouter } from 'vue-router';
import { useDrops } from './useDrops';
import { useTours } from '@/features/tours';
import { computed } from 'vue';
import { navigateToMap } from '@/features/ui/composables';

export const useDropsMiddlemile = (): UseDropsMiddlemile => {
  const {
    currentActiveDrop,
    setCurrentActiveDrop,
    resetRecentlyDoneDrops,
    recentlyDoneDrops,
    addToRecentlyDoneDrops,
    drops,
  } = useDrops();
  const router = useRouter();

  const availableDrops = computed<TourDrop[]>(
    () =>
      drops.value.filter(
        (drop: TourDrop) => drop.status !== DropStatusMiddlemile.drop_completed,
      ) || [],
  );

  const activeDrops = computed(() =>
    drops.value.filter(
      (drop: TourDrop) =>
        drop.status !== DropStatusMiddlemile.open &&
        drop.status !== DropStatusMiddlemile.drop_completed,
    ),
  );

  const applyAction = async (
    tourDrop: TourDrop,
    action: DropActionsMiddlemile,
  ) => {
    setCurrentActiveDrop(tourDrop);
    switch (action) {
      case DropActionsMiddlemile.start_drop_off:
        await startDropOff(tourDrop);
        break;
      case DropActionsMiddlemile.open_route_guidance:
        await navigateToMap(tourDrop.address);
        break;
      case DropActionsMiddlemile.confirm_arrival:
        await confirmArrival(tourDrop);
        break;
      case DropActionsMiddlemile.start_unloading:
        await startUnloading(tourDrop);
        break;
      case DropActionsMiddlemile.continue_unloading:
        await startUnloading(tourDrop, false);
        break;
      case DropActionsMiddlemile.complete_unloading:
        await completeUnloading(tourDrop);
        break;
      case DropActionsMiddlemile.continue_loading:
        await completeUnloading(tourDrop, false);
        break;
      case DropActionsMiddlemile.finish_loading:
        await finishLoading(tourDrop);
        break;
    }
    const dropIndex = drops.value.findIndex(
      (drop) => drop.reference === tourDrop.reference,
    );

    if (!currentActiveDrop.value) return;
    drops.value[dropIndex] = currentActiveDrop.value;
  };

  const startDropOff = async (tourDrop: TourDrop) => {
    await navigateToMap(tourDrop.address);
    await updateDropStatus(tourDrop, DropStatusMiddlemile.delivery_in_progress);
  };

  const confirmArrival = async (tourDrop: TourDrop) => {
    await updateDropStatus(tourDrop, DropStatusMiddlemile.delivery_completed);
  };

  const startUnloading = async (tourDrop: TourDrop, updateStatus = true) => {
    const { currentTour } = useTours();
    if (!currentTour.value) return;

    if (updateStatus) {
      await updateDropStatus(tourDrop, DropStatusMiddlemile.unloading);
    }

    await router.push({
      name: 'unloading-middlemile',
      params: {
        tourId: currentTour.value.id,
        dropId: tourDrop.reference,
      },
    });
  };

  const completeUnloading = async (tourDrop: TourDrop, updateStatus = true) => {
    const { currentTour } = useTours();
    if (!currentTour.value) return;

    if (updateStatus) {
      await updateDropStatus(
        tourDrop,
        DropStatusMiddlemile.loading_empty_frames,
      );
    }

    await router.push({
      name: 'loading-middlemile',
      params: {
        tourId: currentTour.value.id,
        dropId: tourDrop.reference,
      },
    });
  };

  const finishLoading = async (tourDrop: TourDrop) => {
    await updateDropStatus(tourDrop, DropStatusMiddlemile.drop_completed);
    addToRecentlyDoneDrops(tourDrop);

    const { currentTour } = useTours();

    await router.push({
      name: 'tour-drops',
      params: {
        tourId: currentTour.value?.id,
      },
    });
  };

  const updateDropStatus = async (
    tourDrop: TourDrop,
    dropStatus: DropStatusMiddlemile,
  ) => {
    tourDrop.status = dropStatus;
    currentActiveDrop.value = tourDrop;

    try {
      await dropsServicePlugin.get().patchDrop(tourDrop);
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };

  return {
    applyAction,
    setCurrentActiveDrop,
    currentActiveDrop,
    recentlyDoneDrops,
    resetRecentlyDoneDrops,
    drops,
    availableDrops,
    activeDrops,
  };
};
