import { interval } from 'rxjs';
import { MonitoringServiceInterface } from '../types/monitoring-service-interface';
import { MonitoringServiceError } from '../errors';
import { Logger } from '@/features/core/logger';

export class HealthMonitoringService {
  private monitoringServices: Set<MonitoringServiceInterface>;
  constructor(
    private monitoringIntervalInSeconds = 60,
    private loggerService: Logger,
  ) {
    this.monitoringServices = new Set();
  }

  add(monitoringService: MonitoringServiceInterface): void {
    this.monitoringServices.add(monitoringService);
  }

  run(): void {
    interval(this.monitoringIntervalInSeconds * 1000).subscribe(() =>
      this.runMonitoringServices.call(this),
    );
  }

  private runMonitoringServices() {
    this.monitoringServices.forEach((service) => {
      try {
        return void service.trigger();
      } catch (e) {
        const error = new MonitoringServiceError(e);
        this.loggerService.error(error, {});
      }
    });
  }
}
