import { TourDriverNotificationItem } from '../entities/tour-driver-notifiaction';
import { computed, ref, Ref } from 'vue';
import { storagePlugin } from '@/features/core/storage';
import { UseTourDriverNotification } from '../types';
import { toursServicePlugin } from '../services';
import { $t } from '@/i18n';
import { ordersServicePlugin } from '@/features/orders';
import { configurationServicePlugin } from '@/features/configuration';

const tourDriverNotifications: Ref<TourDriverNotificationItem[]> = ref([]);

export const useTourDriverNotification = (): UseTourDriverNotification => {
  const updateNotification = async () => {
    tourDriverNotifications.value = await storagePlugin
      .get()
      .getAll(TourDriverNotificationItem);
    await toursServicePlugin.get().fetchAllTours();
    const pickingOrderDependencyActive = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'driverApp',
        'pickingOrdersDependencyActive',
        'boolean',
      );

    if (!pickingOrderDependencyActive) {
      await ordersServicePlugin.get().fetchAllOrders();
    }
  };

  const notificationLabel = computed(() => {
    const newNotificationsCount = tourDriverNotifications.value.filter(
      (notification) => notification.notificationType === 'new_tour_assignment',
    ).length;
    const removedNotificationsCount = tourDriverNotifications.value.filter(
      (notification) =>
        notification.notificationType === 'tour_assignment_removed',
    ).length;
    const labelParts = [];
    if (newNotificationsCount > 0) {
      labelParts.push(
        $t('components.tours-list.driver-notifications.new-tour-assignment', {
          count: newNotificationsCount,
        }),
      );
    }
    if (removedNotificationsCount > 0) {
      labelParts.push(
        $t('components.tours-list.driver-notifications.tour-removed', {
          count: removedNotificationsCount,
        }),
      );
    }
    return labelParts.join(', ');
  });

  const hasNotification = computed((): boolean => {
    return tourDriverNotifications.value.length > 0;
  });

  const clearNotifications = async () => {
    await storagePlugin.get().removeAll(TourDriverNotificationItem);
    tourDriverNotifications.value = [];
  };

  return {
    updateNotification,
    clearNotifications,
    hasNotification,
    notificationLabel,
  };
};
