import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderPluginDto } from '../types';
import { setPickingEventsAsAlreadyPatched } from '../helpers';

export class SetPickingEventsAsAlreadyPatchedPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.events = setPickingEventsAsAlreadyPatched(
      dataTransferObject.order.events,
    );
    return dataTransferObject;
  }
}
