import { ApiClient } from '@/features/core/api';
import { errorPlugin, HandledError } from '@/features/core/errors';
import { AxiosError, AxiosResponse } from 'axios';
import { Device } from '../entities';
import { DeviceRequestPayload } from '../types';

export class DeviceApiClient {
  constructor(private api: ApiClient) {}

  async save(device: Device): Promise<Device> {
    const requestData = this.getDeviceRequestData(device);
    if (device.isRegistered) {
      try {
        await this.updateDevice(requestData);
      } catch (error) {
        errorPlugin.get().handle(error);
        if (this.isNotFoundAxiosError(error)) {
          await this.registerDevice(requestData);
        }
      }
    } else {
      await this.registerDevice(requestData);
    }
    return new Device();
  }

  private isNotFoundAxiosError(error: unknown): boolean {
    if (!(error instanceof HandledError)) {
      return false;
    }

    const { response } = error.originalError as AxiosError;
    const responseStatusCode = (response as AxiosResponse)?.status;
    return responseStatusCode === 404;
  }

  updateDevice(requestData: DeviceRequestPayload): Promise<unknown> {
    const { deviceId, ...patchPayload } = requestData;
    return this.api.client.patch(`/devices/${deviceId}`, patchPayload);
  }

  private registerDevice(requestData: DeviceRequestPayload): Promise<unknown> {
    return this.api.client.post('/devices', requestData);
  }

  private getDeviceRequestData(device: Device): DeviceRequestPayload {
    const { appVersion, lastLogin, pushToken, id } = device;
    return {
      appVersion,
      lastLoginTimestamp: lastLogin,
      pushToken,
      deviceId: id,
    };
  }
}
