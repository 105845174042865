import { Transmission } from '@/features/transmissions/types';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  ReportData,
  ReportEmail,
  ReportEmailAttachment,
  ReportServiceConfig,
} from '../types';
import { ReportsApiClient } from '../api';

export class ReportsService {
  constructor(
    private apiReport: ReportsApiClient,
    private options: ReportServiceConfig,
  ) {}

  buildReportEmail(data: Transmission | ReportData): ReportEmail {
    return {
      type: 'reports',
      attributes: {
        appVersion: this.options.appVersion,
        subject: `New support information for ${
          'orderReference' in data ? data.orderReference : data.deviceId
        }`,
        description:
          'orderReference' in data
            ? this.buildFailedTransmissionReportEmailDescription(data)
            : this.buildReportEmailDescription(data),
        attachments: [] as ReportEmailAttachment[],
      },
    };
  }

  private buildFailedTransmissionReportEmailDescription(
    transmission: Transmission,
  ): string {
    const scheduledAt = transmission.timestamps?.scheduledAt.toString();
    const failReason = transmission.timestamps?.failReason;
    const retries = transmission.timestamps?.retries;

    return `
    <div>
      <p>A new support request has come in. Please find the details below:</p>
      <ul>
        <li>Order Reference: ${transmission.orderReference}</li>
        <li>Scheduled At: ${String(scheduledAt)}</li>
        <li>Fail Reason: ${String(failReason)}</li>
        <li>Retries: ${String(retries)}</li>
        <li>Callback: ${transmission.title}</li>
        <li>Device ID: ${String(transmission.deviceId)}</li>
        <li>UserID: ${String(transmission.userId)}</li>
      </ul>
    </div>`;
  }

  private buildReportEmailDescription(data: ReportData): string {
    return `
    <div>
      <p>A new support request has come in. Please find the details below: </p>
      <ul>
        <li>This email is automatically generated due to an app crash.</li>
        <li>Error: ${String(data.error.message)}</li>
        <li>Page url: ${window?.location?.href}</li>
        <li>Device ID: ${data.deviceId}</li>
      </ul>
    </div>`;
  }

  async sendErrorReport(data: Transmission | ReportData): Promise<boolean> {
    const errorData = this.buildReportEmail(data);
    try {
      await this.apiReport.save(errorData);
      return true;
    } catch (error) {
      loggerServicePlugin.get().info(`Send report error ${String(error)}`);
      return false;
    }
  }
}
